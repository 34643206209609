@import url("./spinner.css");

/* @import url("./styles/telstra-able.css"); */
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important;
  outline: none;
}

*:focus-visible:not(:focus) {
  box-shadow: 0 0 0 0.1875rem #0099f8;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
}

html, body {
  overscroll-behavior-x: none;
  scroll-behavior: smooth;
}

body {
  /* font-family: Telstra Akkurat,Arial, Helvetica, sans-serif; */
  overflow-x: hidden;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  background: #fff;
}

#page-content {
  position: relative;
  min-height: 100vh;
}

.page-container {
  width: 100%;
  max-width: 436px;
  display: flex;
  margin: auto;
  padding: 0 8px;
  position: relative;
  background: #fff;
}

main {
  min-height: calc(100vh - 135px);
}

.bg-gray {
  background-color: rgba(244, 244, 244, 1)
}

.bg-gray .page-container {
  background-color: transparent
}

.white-surface {
  background-color: #fff;
}

.white-surface:after {
  display: none;
}

@media screen and (max-width: 767px) {
  .page-container {
    max-width: 350px;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  .page-container {
    max-width: 416px;
  }
}

@media screen and (min-width: 1024px) {
  .page-container {
    max-width: 436px;
  }
}

.page-container .able-Grid,
.page-container .able-Spacing,
.page-container .able-Grid--padding--false {
  width: 100%;
}

.whiteBg {
  background: #fff;
}

.header-comm {
  width: 100%;
  z-index: 2;
  position: relative;
  background: #fff;
}

.header-comm .t-logo-mobile img {
  margin-top: 12px;
}

.pull-right {
  float: right;
}

.footer-comm {
  position: relative;
  bottom: 0;
  left: 0;
  background-color: #f7f7f7;
  text-align: center;
  width: 100%;
  padding: 0;
}

.footer-links a {
  margin-left: 10px;
  color: #414141;
}

.fullwidth {
  width: 100% !important;
}

.pageLoader {
  text-align: center;
  height: calc(100vh - 200px);
  align-items: center;
  justify-content: center;
  display: flex;
}

.spinner-box {
  width: 100%;
  background-color: #fff;
  text-align: center;
  /* align-items: center;
  justify-content: center;
  display: flex; */
  /* display: grid;
  text-align: center;
  justify-content: center;
  background-color: transparent; */
  /* position: fixed;
  z-index: 999; */
}

.spinner-box.fixedCIDLoader {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  /* background-color: rgba(255, 255, 255, .7) */
}

.server-spinner-box {
  height: 160px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.cnsbColor {
  color: #0d54ff !important;
  cursor: pointer;
}

.round-spinner {
  display: block;
  width: 50px;
  height: 50px;
  border: 3px solid #001783;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin .5s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.lightBg {
  background: rgb(236, 247, 254)
}

.able-DayMonthYearGroup fieldset>div div {
  display: block;
  width: 100%;
}

.able-DayMonthYearGroup.disableDate fieldset>div div:first-child {
  pointer-events: none;
  display: none;
}

.able-DayMonthYearGroup.disableDate fieldset>div div {
  flex-basis: 48%;
}

.able-DayMonthYearGroup.disableDate fieldset>div div+div {
  margin-left: 0px;
}

.able-DayMonthYearGroup fieldset>div {
  justify-content: space-between;
}

.able-DayMonthYearGroup fieldset>div div input {
  width: 100%;
}


.t-text-center {
  text-align: center;
}

.images-group {
  display: flex;
  justify-content: center;
}

.images-group img {
  margin: .5rem;
  margin-bottom: 0;
  height: 40px;
  display: inline-block;
}

.footerDocBtn {
  bottom: 60px
}

footer {
  bottom: 60px
}

.idScan-body iframe {
  display: none;
}

.img-responsive {
  max-width: 100%;
}

.noIcon svg {
  display: none;
}

.page-container .able-Grid.buttonGroup>div {
  padding-left: 1rem;
  padding-right: 1rem;
}

.page-container .able-Grid.buttonGroup {
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  flex-direction: row-reverse;
}

.buttonGroup {
  display: flex;
}

.buttonGroup button+button {
  margin-left: 1.5rem;
}

.able-ModalContent--Compact [class$=__content] {
  /* width: 400px !important; */
}

@media screen and (max-width: 360px) {
  .able-ModalContent--Compact [class$=__content], .able-ModalDialog--Confirmation [class$=__content] {
    max-width: 306px !important;
  }
}

@media screen and (max-width: 768px) {
  .able-ModalContent--Compact [class$=__content], .able-ModalDialog--Confirmation [class$=__content] {
    max-width: 572px !important;
  }
}

@media screen and (min-width: 48rem) {
  .able-ModalDialog--Confirmation__content {
    max-width: 572px !important;
  }
}

@media screen and (min-width: 4000px) {
  .able-ModalDialog--Confirmation__content {
    max-width: 572px !important;
    top: 25% !important
  }
}

@media screen and (max-width: 767px) {
  .t-logo-mobile {
    margin: auto;
    padding: 0;
  }

  .able-ModalDialog--Confirmation__content {
    max-width: 350px;
  }
}

.able-ModalDialog--ConfirmationDestructive__content, .able-ModalDialog--CriticalDecision__content {
  max-width: 350px;
}

.able-ModalContent--Compact_footer {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: flex-end;
}

.able-editbleName footer {
  display: none;
}

.able-fonts, .able-ModalDialog--Confirmation__content {
  font-family: Telstra Text Variable, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.able-fonts h1,
.able-fonts h2,
.able-fonts h3,
.able-fonts h4,
.able-fonts h5 {
  font-weight: 500;
}

@media screen and (max-width: 767px) {
  .serversGrid {
    grid-template-columns: 1fr
  }
}

@media screen and (max-width: 1023px) {
  .able-pt-spacing-half {
    padding-top: 0.25rem;
  }

  .able-pt-spacing1x {
    padding-top: 0.5rem;
  }

  .able-pt-spacing2x {
    padding-top: 1rem;
  }

  .able-pt-spacing3x {
    padding-top: 1.5rem;
  }

  .able-pt-spacing4x {
    padding-top: 2rem;
  }

  .able-pt-spacing5x {
    padding-top: 2.5rem;
  }

  .able-pt-spacing7x {
    padding-top: 3.5rem;
  }

  .able-pt-spacing10x {
    padding-top: 5rem;
  }

  .able-pl-spacing-half {
    padding-left: 0.25rem;
  }

  .able-pl-spacing1x {
    padding-left: 0.5rem;
  }

  .able-pl-spacing2x {
    padding-left: 1rem;
  }

  .able-pl-spacing3x {
    padding-left: 1.5rem;
  }

  .able-pl-spacing4x {
    padding-left: 2rem;
  }

  .able-pl-spacing5x {
    padding-left: 2.5rem;
  }

  .able-pl-spacing7x {
    padding-left: 3.5rem;
  }

  .able-pl-spacing10x {
    padding-left: 5rem;
  }

  .able-pr-spacing-half {
    padding-right: 0.25rem;
  }

  .able-pr-spacing1x {
    padding-right: 0.5rem;
  }

  .able-pr-spacing2x {
    padding-right: 1rem;
  }

  .able-pr-spacing3x {
    padding-right: 1.5rem;
  }

  .able-pr-spacing4x {
    padding-right: 2rem;
  }

  .able-pr-spacing5x {
    padding-right: 2.5rem;
  }

  .able-pr-spacing7x {
    padding-right: 3.5rem;
  }

  .able-pr-spacing10x {
    padding-right: 5rem;
  }

  .able-pb-spacing-half {
    padding-bottom: 0.25rem;
  }

  .able-pb-spacing1x {
    padding-bottom: 0.5rem;
  }

  .able-pb-spacing2x {
    padding-bottom: 1rem;
  }

  .able-pb-spacing3x {
    padding-bottom: 1.5rem;
  }

  .able-pb-spacing4x {
    padding-bottom: 2rem;
  }

  .able-pb-spacing5x {
    padding-bottom: 2.5rem;
  }

  .able-pb-spacing7x {
    padding-bottom: 3.5rem;
  }

  .able-pb-spacing10x {
    padding-bottom: 5rem;
  }

  .t-able-spacing-v-mb-half {
    padding-bottom: 0.25rem;
  }

  .t-able-spacing-v-mb-1x {
    margin-bottom: 0.5rem;
  }

  .t-able-spacing-v-mb-2x {
    margin-bottom: 1rem;
  }

  .t-able-spacing-v-mb-3x {
    margin-bottom: 1.5rem;
  }

  .t-able-spacing-v-mb-4x {
    margin-bottom: 2rem;
  }

  .t-able-spacing-v-mb-5x {
    margin-bottom: 2.5rem;
  }

  .t-able-spacing-v-mb-7x {
    margin-bottom: 3.5rem;
  }

  .t-able-spacing-v-mb-10x {
    margin-bottom: 5rem;
  }
}

@media screen and (min-width: 1024px) {
  .able-pt-spacing-half {
    padding-top: 0.5rem;
  }

  .able-pt-spacing1x {
    padding-top: 1rem;
  }

  .able-pt-spacing2x {
    padding-top: 1rem;
  }

  .able-pt-spacing3x {
    padding-top: 1.5rem;
  }

  .able-pt-spacing4x {
    padding-top: 2.5rem;
  }

  .able-pt-spacing5x {
    padding-top: 3.5rem;
  }

  .able-pt-spacing7x {
    padding-top: 5rem;
  }

  .able-pt-spacing10x {
    padding-top: 7.5rem;
  }

  .able-pt-spacing-half {
    padding-bottom: 0.5rem;
  }

  .able-pb-spacing1x {
    padding-bottom: 1rem;
  }

  .able-pb-spacing2x {
    padding-bottom: 1rem;
  }

  .able-pb-spacing3x {
    padding-bottom: 1.5rem;
  }

  .able-pb-spacing4x {
    padding-bottom: 2.5rem;
  }

  .able-pb-spacing5x {
    padding-bottom: 3.5rem;
  }

  .able-pb-spacing7x {
    padding-bottom: 5rem;
  }

  .able-pb-spacing10x {
    padding-bottom: 7.5rem;
  }

  .able-pl-spacing-half {
    padding-left: 0.5rem;
  }

  .able-pl-spacing1x {
    padding-left: 1rem;
  }

  .able-pl-spacing2x {
    padding-left: 1rem;
  }

  .able-pl-spacing3x {
    padding-left: 1.5rem;
  }

  .able-pl-spacing4x {
    padding-left: 2.5rem;
  }

  .able-pl-spacing5x {
    padding-left: 3.5rem;
  }

  .able-pl-spacing7x {
    padding-left: 5rem;
  }

  .able-pl-spacing10x {
    padding-left: 7.5rem;
  }

  .able-pr-spacing-half {
    padding-right: 0.5rem;
  }

  .able-pr-spacing1x {
    padding-right: 1rem;
  }

  .able-pr-spacing2x {
    padding-right: 1rem;
  }

  .able-pr-spacing3x {
    padding-right: 1.5rem;
  }

  .able-pr-spacing4x {
    padding-right: 2.5rem;
  }

  .able-pr-spacing5x {
    padding-right: 3.5rem;
  }

  .able-pr-spacing7x {
    padding-right: 5rem;
  }

  .able-pr-spacing10x {
    padding-right: 7.5rem;
  }
}

.cid-idp-selector-button:hover, .cid-idp-selector-button:active, .cid-idp-selector-button:focus-visible {
  background-color: #111;
}

.cid-idp-selector-button:focus-visible {
  box-shadow: 0 0 0 0.125rem #555, 0 0 0 0.25rem #666;
}

.cid-idp-selector-button:focus-visible {
  outline: none;
}

#documentNumber, #cardNumber {
  text-transform: uppercase;
}

.able-common__error {
  color: #d0021b;
  margin-top: .25rem;
  font-size: 0.875rem;
  line-height: 1.43;
}

.able-Checkbox--Comfortable.checkboxErr input:focus:not(:checked):not(:focus-visible)~label::before {
  box-shadow: inset 0 0 0 .0625rem rgba(0, 0, 0, 0), 0 0 0 .125rem #d0021b, 0 0 0 .1875rem rgba(0, 0, 0, 0);
}

.able-Checkbox--Comfortable.checkboxErr input:focus:not(:checked):not(:focus-visible)~label::after {
  box-shadow: none;
  border-color: #d0021b;
}

/* connect-id styles */
.cid-idp-selector-popup.cid-idp-selector-popup--success .cid-idp-selector-popup-participants,
.cid-idp-selector-popup-participants {
  grid-template-columns: 1fr;
  margin: 0;
  grid-gap: 0
}

.cid-idp-selector-popup-claims {
  grid-template-columns: 1fr;
  grid-gap: 8px;
  margin-top: 8px;
}

.cid-idp-selector-popup-claim>svg {
  fill: #fff !important;
  margin-right: 8px;
  margin-top: 2px;
  height: 20px;
  width: 20px;
  color: rgb(19, 26, 53);
}

.cid-idp-selector-popup-participants {
  max-height: 100%;
  overflow: inherit;
}

.cid-idp-selector-popup-logo,
.cid-idp-selector-popup-logo-rp {
  height: 72px;
  width: 72px;
  border-radius: 10px
}

.cid-idp-selector-popup-infobox {
  display: none;
}

.cid-idp-selector-popup-title,
.cid-idp-selector-popup-participants-title, .cid-idp-selector-popup-claims-title {
  color: rgb(19, 26, 53);
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 28px;
}

.cid-idp-selector-popup-title-description, .cid-idp-selector-popup-claim {
  color: rgb(19, 26, 53);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 24px;
}

.cid-idp-selector-popup-participant-name {
  color: rgb(19, 26, 53);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 24px;
}

#cid-idp-selector, .cid-idp-selector-popup-title, .cid-idp-selector-popup-title-description,
.cid-idp-selector-popup-participants-title, .cid-idp-selector-popup-claims-title,
.cid-idp-selector-popup-claim, .cid-idp-selector-popup-participant-name {
  font-family: Telstra Display, Helvetica Neue, Helvetica, Arial, sans-serif;
}

.cid-idp-selector-popup-logo-wrapper {
  justify-content: center;
}

.cid-idp-selector-popup-title,
.cid-idp-selector-popup-title-description {
  padding: 0 24px;
}

.cid-idp-selector-popup-claims-wrapper,
.cid-idp-selector-popup-provider-wrapper {
  padding: 24px;
  background: #fff;
}

.cid-idp-selector-popup-claims-wrapper {
  margin-bottom: 32px;
}

.cid-idp-selector-popup-participant {
  padding: 0;
  border: 1px solid transparent;
  border-radius: 0px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cid-idp-selector-popup-participant,
.cid-idp-selector-popup-participant:hover,
.cid-idp-selector-popup-participant:focus {
  border: 0px
}

.cid-idp-selector-popup-participant-button {
  border: 1px solid transparent;
  border-radius: 0;
  padding: 15px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.cid-idp-selector-popup-participant-button:hover,
.cid-idp-selector-popup-participant-button:focus {
  overflow: auto;
  border-bottom: 1px solid transparent;
  box-shadow: 0 0 0 0.0875rem #0d54ff
}

.cid-idp-selector-popup-participant-button:focus-visible {
  overflow: auto;
  border-bottom: 1px solid transparent;
  box-shadow: 0 0 0 .125rem var(--focusedForeground), 0 0 0 .25rem var(--focusedBorder);
}

.cid-idp-selector-popup-participant-icon {
  width: 56px;
  height: 56px;
}

.cid-idp-selector-popup-participant:after {
  position: absolute;
  background-image: url("./arrow-right-line.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 100%;
  top: 0;
  right: 5px;
  content: "";
  transition: all ease-in-out .5s;
}

.cid-idp-selector-popup-participant-button:hover:after {
  right: 10px;
}

.cid-idp-selector-popup-participants-title {
  margin-bottom: 8px;
}

.cid-idp-selector-popup-claim svg path {
  fill: none;
}

.cid-idp-selector-button {
  width: 100%;
  border-radius: 2.25rem;
  font-size: 1rem;
}

.cid-idp-selector-popup-provider-wrapper,
.cid-idp-selector-popup.cid-idp-selector-popup--inline .cid-idp-selector-popup-claims-button-wrapper,
.cid-idp-selector-popup-claims-wrapper {
  border-radius: 0px;
  border: 0px none;
}

ul.connectId-Ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.connectId-Ul li {
  position: relative;
  list-style-type: none;
  padding-left: 32px;
}

ul.connectId-Ul li::before {
  position: absolute;
  background-image: url("./Tick.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  top: 0;
  left: 0;
  content: "";
  transition: all ease-in-out .5s;
}

ul.connectId-Ul li.terms_condition::before{
  background-image: url("./icon-Documentation.svg");
}

.connectID_bg, .authenticated_bg {
  background: rgb(251, 248, 243);
  border-radius: 8px;
}

.finePrintA-color {
  color: #4a4d55
}

@media (max-width: 482px) {
  .cid-idp-selector-popup-title, .cid-idp-selector-popup-title-description {
    padding: 0px;
  }
}