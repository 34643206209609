@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-Accordion--compact {
  @include able.Accordion('Compact');
}

.able-Accordion--comfortable {
  @include able.Accordion('Comfortable');
}

.able-Accordion--contained {
  @include able.Accordion('Contained');
}

.able-Accordion--top-divider-on {
  @include able.AccordionTopDividerOn();
}

.eg-contained-variant-paragraph {
  @include able.TextStyle(Base);
  padding: able.spacing(spacing2x) 0 0 able.spacing(spacing4x);
}

.eg-comfortable-variant-coloured-background {
  padding: able.spacing(spacing2x);
}
