@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-Card {
  &--Destination {
    @include able.Card(Destination);
  }

  &--Destination--ImagePositionTop {
    @include able.Card(Destination, $image-position: top);
  }

  &--Destination--ImagePositionBottom {
    @include able.Card(Destination, $image-position: bottom);
  }

  &--Destination--ImagePositionLeft {
    @include able.Card(Destination, $image-position: left);
  }

  &--Destination--ImagePositionRight {
    @include able.Card(Destination, $image-position: right);
  }

  &--Complex {
    @include able.Card(Complex);
  }

  &--Complex--ImagePositionTop {
    @include able.Card(Complex, $image-position: top);
  }

  &--Complex--ImagePositionBottom {
    @include able.Card(Complex, $image-position: bottom);
  }

  &--Complex--ImagePositionLeft {
    @include able.Card(Complex, $image-position: left);
  }

  &--Complex--ImagePositionRight {
    @include able.Card(Complex, $image-position: right);
  }

  &--ComplexBorderOff {
    @include able.CardComplexVariantBorderOff();
  }
}

.eg--card {
  margin: auto;
  max-width: 420px;
}
