@use "../var" with ( $include-breakpointLG: false );@use '..' as able;

.able-ButtonGroupSequence {
  &--Default {
    &--Normal {
      @include able.ButtonGroupSequence(Default, Normal);
    }

    &--Start {
      @include able.ButtonGroupSequence(Default, Start);
    }

    &--End {
      @include able.ButtonGroupSequence(Default, End);
    }
  }

  &--Full {
    &--Normal {
      @include able.ButtonGroupSequence(Full, Normal);
    }

    &--Start {
      @include able.ButtonGroupSequence(Full, Start);
    }

    &--End {
      @include able.ButtonGroupSequence(Full, End);
    }
  }

  &--Form {
    &--Normal {
      @include able.ButtonGroupSequence(Form, Normal);
    }

    &--Start {
      @include able.ButtonGroupSequence(Form, Start);
    }

    &--End {
      @include able.ButtonGroupSequence(Form, End);
    }
  }
}
